import React, { useState } from "react";
import { MenuItem, FormControl, Select, colors } from "@mui/material";
import { makeStyles } from "@mui/styles";
import i18next from "i18next";
import localesData from "../utils/localesData";
import { countryToFlag } from "../utils/masterData";

const useStyles = (trigger) =>
  makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
    },
    languageSelector: {
      border: "0px",
      color: trigger ? colors.blue[900] : colors.grey[50],
    },
    icon: {
      fill: trigger ? colors.blue[900] : colors.grey[50],
    },
  }));

const LanguagePopover = ({ trigger }) => {
  const classes = useStyles(trigger)();
  const [currentLanguage, setCurrentLanguage] = useState(
    i18next.resolvedLanguage
  );
  const { getLanguages } = localesData();
  const languages = getLanguages;

  const changeLanguage = (event) => {
    event.preventDefault();

    i18next.changeLanguage(event.target.value);
    setCurrentLanguage(event.target.value);
  };

  return (
    <>
      <FormControl variant="standard" className={classes.formControl}>
        <Select
          className={classes.languageSelector}
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
          value={currentLanguage ?? ""}
          onChange={changeLanguage}
          disableUnderline
        >
          {languages.map((language) => (
            <MenuItem key={language.languageCode} value={language.languageCode}>
              {countryToFlag(`${language.countryCode}`)}&nbsp;&nbsp;
              {language.languageLabel}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default LanguagePopover;
