import React, { useState } from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  colors,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { YouTube } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ShowVideo from "../../components/ShowVideo";
import { concepts } from "../../utils/masterData";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: colors.grey[50],
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(14),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(8),
    },
  },
  header: {
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  section: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  content: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    border: "1px solid",
    borderColor: colors.grey[200],
    borderRadius: 3,
    [theme.breakpoints.up("xs")]: {
      height: "28vh",
      width: "88vw",
    },
    [theme.breakpoints.up("sm")]: {
      height: "58vh",
      width: "40vw",
    },
    [theme.breakpoints.up("md")]: {
      height: "35vh",
      width: "28vw",
    },
    "&:hover": {
      boxShadow:
        "0 1.5rem 2.5rem rgba(22,28,45,.1),0 .3rem 0.5rem -.50rem rgba(22,28,45,.05) !important",
      transform: "translate3d(0,-5px,0)",
    },
  },
  conceptTitle: {
    fontWeight: "bold",
  },
  conceptSubtitle: {
    color: colors.grey[700],
  },
}));

const LandingConcepts = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [videoURL, setVideoURL] = useState(null);
  const [openVideoDialog, setOpenVideoDialog] = useState(false);

  const handleOpenVideo = (youTubeSuffix) => {
    setVideoURL(youTubeSuffix);
    setOpenVideoDialog(true);
  };

  const handleCloseVideo = () => {
    setOpenVideoDialog(false);
  };

  return (
    <>
      <Container className={classes.container}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className={classes.header}
        >
          <Grid item>
            <Typography variant="h2" sx={{ color: colors.indigo[900] }}>
              {t("websiteLabelSahajaYoga")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" sx={{ color: colors.grey[700] }}>
              {t("websiteHomeWelcomeSubtitle")}
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={4}
            columns={{ xs: 4, sm: 8, md: 12 }}
            className={classes.section}
          >
            {concepts.map((concept, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Box className={classes.content}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item key={index}>
                      <Typography variant="h5" className={classes.conceptTitle}>
                        {t(concept.title)}
                      </Typography>
                    </Grid>
                    <Grid item key={index}>
                      <Typography
                        variant="body1"
                        className={classes.conceptSubtitle}
                      >
                        {t(concept.subtitle)}
                      </Typography>
                    </Grid>
                    <Grid item key={index}>
                      <Button
                        variant="contained"
                        startIcon={<YouTube />}
                        style={{
                          backgroundColor: concept.color,
                          color: concept.textColor,
                          borderRadius: 3,
                          boxShadow: "1px 1px grey",
                        }}
                        onClick={() => handleOpenVideo(concept.youTubeId)}
                      >
                        <Typography variant="overline">
                          {t("websiteButtonWatchVideo")}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>

      <ShowVideo
        videoURL={videoURL}
        openDialog={openVideoDialog}
        onCloseDialog={handleCloseVideo}
      />
    </>
  );
};

export default LandingConcepts;
