import React from "react";
import { Drawer } from "@mui/material";
import SidebarContent from "../../components/SidebarContent";

const WebsiteSideBar = ({ open, onClose }) => {
  return (
    <>
      <Drawer anchor="right" open={open} onClose={onClose}>
        <SidebarContent onContentSelect={onClose} />
      </Drawer>
    </>
  );
};

export default WebsiteSideBar;
