import Modal from "@material-ui/core/Modal";
import ReactPlayer from "react-player/youtube";

const ShowVideo = ({ videoURL, openDialog, onCloseDialog }) => {
  const url = `https://www.youtube.com/watch?v=${videoURL}`;

  return (
    <div>
      <Modal
        open={openDialog}
        onClose={onCloseDialog}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ReactPlayer url={url} controls />
      </Modal>
    </div>
  );
};

export default ShowVideo;
