import React from "react";
import LandingHero from "../../components/landing-page/LandingHero";
import LandingConcepts from "../../components/landing-page/LandingConcepts";
import LandingSolutions from "../../components/landing-page/LandingSolutions";
import LandingTestimonials from "../../components/landing-page/LandingTestimonials";

const LandingPage = () => {
  return (
    <>
      <LandingHero />
      <sectin id="concepts">
        <LandingConcepts />
      </sectin>
      <sectin id="solutions">
        <LandingSolutions />
      </sectin>
      <sectin id="testimonials">
        <LandingTestimonials />
      </sectin>
    </>
  );
};

export default LandingPage;
