import React, { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { SnackbarProvider } from "notistack";
import ThemeConfig from "./theme";
import renderRoutes from "./routes";

function App() {
  return (
    <Suspense fallback="...loading">
      <HelmetProvider>
        <ThemeConfig>
          <SnackbarProvider
            dense
            maxSnack={1}
            hideIconVariant={false}
            preventDuplicate
          >
            {renderRoutes()}
          </SnackbarProvider>
        </ThemeConfig>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
