import React from "react";
import { Link } from "react-router-dom";
import { Container, Box, Grid, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "@mui/material";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { MotionContainer, varBounceIn } from "../../components/animate";
import Logo from "../../components/Logo";
import { organizationInfo } from "../../utils/masterData";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: { padding: theme.spacing(5, 5, 0) },
}));

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <RootStyle>
      <HeaderStyle>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Logo trigger={true} />
            </Grid>
            <Grid item>
              <Typography
                variant="overline"
                sx={{ color: colors.blue[900] }}
                fontSize={15}
              >
                {organizationInfo.brandName}
              </Typography>
            </Grid>
          </Grid>
        </Link>
      </HeaderStyle>

      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" gutterBottom>
                {t("website404Title")}
              </Typography>
            </motion.div>
            <Typography sx={{ color: "text.secondary" }}>
              {t("website404Subtitle")}
            </Typography>

            <Box
              component={motion.img}
              variants={varBounceIn}
              alt="404"
              src="/assets/illustrations/illustration_404_undraw.svg"
              sx={{ width: "100%", maxHeight: 240, my: { xs: 5, sm: 10 } }}
            />

            <Button
              variant="contained"
              size="large"
              style={{ backgroundColor: colors.blue[700] }}
              component={Link}
              to="/"
            >
              {t("websiteButtonGoBack")}
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
};

export default Page404;
