import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import WebsiteLayout from "../layouts/website";
import LandingPage from "../views/website/LandingPage";
import Page404 from "../views/website/Page404";

const renderRoutes = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route path="/" element={<WebsiteLayout />}>
          <Route path="/" exact element={<LandingPage />} />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  );
};

export default renderRoutes;
