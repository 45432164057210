import React from "react";
import { Container, Grid, Typography, colors } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { organizationInfo } from "../../utils/masterData";

const WebsiteFooter = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          textAlign: "center",
          py: 2,
          backgroundColor: colors.blue[500],
          color: colors.grey[50],
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          //spacing={1}
        >
          <Grid item>
            <Typography variant="body1">
              &copy; {moment().format("YYYY")} {organizationInfo.name}{" "}
              {t("websiteCopyright")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{organizationInfo.email}</Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default WebsiteFooter;
