import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  Card,
  CardMedia,
  AppBar,
  Toolbar,
  Drawer,
  colors,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { YouTube, ArrowRightAlt } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ShowVideo from "../../components/ShowVideo";
import { organizationInfo, solutions } from "../../utils/masterData";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(14),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(8),
    },
  },
  solutionTitle: {
    fontWeight: "bold",
    height: "12vh",
  },
  solutionSubtitle: {
    color: colors.grey[700],
    [theme.breakpoints.up("xs")]: {
      height: "18vh",
      width: "65vw",
    },
    [theme.breakpoints.up("sm")]: {
      height: "18vh",
      width: "80vw",
    },
    [theme.breakpoints.up("md")]: {
      height: "18vh",
      width: "28vw",
    },
  },
  image: {
    maxWidth: 300,
    borderRadius: 3,
  },
  boldText: {
    fontWeight: "bold",
  },
  videoButton: {
    backgroundColor: colors.grey[200],
    color: colors.grey[900],
    borderRadius: 3,
    boxShadow: "1px 1px grey",
    "&:hover": {
      backgroundColor: colors.grey[200],
      color: colors.grey[900],
      boxShadow: "1px 1px grey",
    },
  },
  registerButton: {
    backgroundColor: colors.blue[500],
    color: colors.grey[50],
    borderRadius: 3,
    boxShadow: "1px 1px grey",
    "&:hover": {
      backgroundColor: colors.blue[500],
      color: colors.grey[50],
      boxShadow: "1px 1px grey",
    },
  },
  youtube: {
    color: "#FF0000",
  },
  appBarBottom: {
    top: "auto",
    bottom: 0,
    background: "transparent",
    boxShadow: "none",
  },
  toolbarBottom: {
    width: "100%",
    margin: "0 auto",
    padding: theme.spacing(0, 2),
  },
  contactForm: {
    padding: theme.spacing(3, 2),
    margin: "0 auto",
  },
  blueText: {
    color: colors.blue.A700,
  },
}));

const LandingSolutions = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [videoURL, setVideoURL] = useState(null);
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [openBottombar, setOpenBottombar] = useState(false);
  const [code, setCode] = useState("general");
  const [message, setMessage] = useState("General");

  const handleOpenVideo = (youTubeSuffix) => {
    setVideoURL(youTubeSuffix);
    setOpenVideoDialog(true);
  };

  const handleCloseVideo = () => {
    setOpenVideoDialog(false);
  };

  const handleBottombarOpen = (code, title) => {
    setCode(code);
    setMessage(title);
    setOpenBottombar(true);
  };

  const handleBottombarClose = () => {
    setOpenBottombar(false);
  };

  return (
    <>
      <Container className={classes.container}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={7}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={5}
            >
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="h2" sx={{ color: colors.indigo[900] }}>
                      {t("websiteHomeSolutionTitle")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" sx={{ color: colors.grey[700] }}>
                      {t("websiteHomeSolutionSubtitle")}
                    </Typography>
                    <Typography variant="h5" sx={{ color: colors.grey[700] }}>
                      {t("websiteShriMatajiFullName")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} align={{ md: "right" }}>
                <Card raised className={classes.image}>
                  <CardMedia
                    component="img"
                    alt="Subtle System"
                    image="/assets/images/subtle-system.gif"
                  ></CardMedia>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={{ xs: 4, sm: 2 }}
            >
              {solutions.map((solution, index) => (
                <Grid item key={index} xs={12} md={4}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        className={classes.solutionTitle}
                      >
                        {t(solution.title)}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.solutionSubtitle}>
                      <Typography variant="body1">
                        {t(solution.subtitle)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Button
                            variant="contained"
                            className={classes.videoButton}
                            startIcon={<YouTube className={classes.youtube} />}
                            onClick={() => handleOpenVideo(solution.youTubeId)}
                          >
                            <Typography variant="overline">
                              {t("websiteButtonWatchVideo")}
                            </Typography>
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            className={classes.registerButton}
                            endIcon={<ArrowRightAlt />}
                            onClick={() =>
                              handleBottombarOpen(
                                solution.solutionCode,
                                solution.title
                              )
                            }
                          >
                            <Typography variant="overline">
                              {t("websiteButtonRegister")}
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <ShowVideo
        videoURL={videoURL}
        openDialog={openVideoDialog}
        onCloseDialog={handleCloseVideo}
      />

      <AppBar position="fixed" className={classes.appBarBottom}>
        <Toolbar disableGutters className={classes.toolbarBottom}>
          <Drawer
            anchor="bottom"
            open={openBottombar}
            onClose={handleBottombarClose}
          >
            <div className={classes.contactForm}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h3" className={classes.boldText}>
                    {t(message)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    {t("websiteHomeSolutionRegistrationTextOne")}
                    <strong>{organizationInfo.email}</strong>
                    <br />
                    {t("websiteHomeSolutionRegistrationTextTwo")}
                    <strong>{code}</strong>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Drawer>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default LandingSolutions;
