const LANGUAGES = [
  {
    languageCode: "bg",
    languageLabel: "български",
    countryCode: "BG",
    default: false,
  },
  {
    languageCode: "de",
    languageLabel: "Deutsche",
    countryCode: "DE",
    default: false,
  },
  {
    languageCode: "el",
    languageLabel: "Ελληνικά",
    countryCode: "GR",
    default: false,
  },
  {
    languageCode: "en",
    languageLabel: "English",
    countryCode: "GB",
    default: true,
  },
  {
    languageCode: "es",
    languageLabel: "Español",
    countryCode: "ES",
    default: false,
  },
  {
    languageCode: "fr",
    languageLabel: "Français",
    countryCode: "FR",
    default: false,
  },
  {
    languageCode: "hu",
    languageLabel: "Magyar",
    countryCode: "HU",
    default: false,
  },
  {
    languageCode: "it",
    languageLabel: "Italiano",
    countryCode: "IT",
    default: false,
  },
  {
    languageCode: "lt",
    languageLabel: "Lietuvis",
    countryCode: "LT",
    default: false,
  },
  {
    languageCode: "nl",
    languageLabel: "Nederlands",
    countryCode: "NL",
    default: false,
  },
  {
    languageCode: "pt",
    languageLabel: "Português",
    countryCode: "PT",
    default: false,
  },
  {
    languageCode: "ro",
    languageLabel: "Română",
    countryCode: "RO",
    default: false,
  },
  {
    languageCode: "tr",
    languageLabel: "Türkce",
    countryCode: "TR",
    default: false,
  },
  {
    languageCode: "uk",
    languageLabel: "Український",
    countryCode: "UA",
    default: false,
  },
];

const localesData = () => {
  const handleGetLanguageCodes = () => {
    const tmpArray = [];

    LANGUAGES.forEach((language, i) => {
      tmpArray.push(language.languageCode);
    });

    return tmpArray;
  };

  const handleGetDefaultLanguageCodes = () => {
    const tmpArray = [];

    LANGUAGES.forEach((language, i) => {
      if (language.default) {
        tmpArray.push(language.languageCode);
      }
    });

    return tmpArray;
  };

  return {
    getLanguageCodes: handleGetLanguageCodes,
    getDefaultLanguageCodes: handleGetDefaultLanguageCodes,
    getLanguages: LANGUAGES,
  };
};

export default localesData;
