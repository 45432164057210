import React from "react";
import { Container, Grid, Typography, colors } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ReactPlayer from "react-player/youtube";
import { useTranslation } from "react-i18next";
import { reviews } from "../../utils/masterData";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: colors.grey[50],
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(14),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(8),
    },
  },
  header: {
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  section: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

const LandingTestimonials = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Container className={classes.container}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className={classes.header}
        >
          <Grid item>
            <Typography variant="h2" sx={{ color: colors.indigo[900] }}>
              {t("websiteHomeTestimonialTitle")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" sx={{ color: colors.grey[700] }}>
              {t("websiteHomeTestimonialSubtitle")}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={{ xs: 4, sm: 0 }}
          className={classes.section}
        >
          {reviews.map((review, index) => (
            <Grid item key={index} xs={12} md={4}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <ReactPlayer
                    url={`https://www.youtube.com/watch?v=${review.youTubeId}`}
                    height={200}
                    width={325}
                    controls
                  />
                </Grid>
                <Grid item>{t(review.country)}</Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default LandingTestimonials;
