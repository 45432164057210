import React from "react";
import { Box } from "@mui/material";

const Logo = ({ trigger }) => {
  return (
    <Box
      component="img"
      alt="logo"
      src={
        trigger ? "/assets/logo/logo_blue.svg" : "/assets/logo/logo_white.svg"
      }
      onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
      height={32}
    />
  );
};

export default Logo;
