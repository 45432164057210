import React from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Grid,
  Typography,
  IconButton,
  Hidden,
  useScrollTrigger,
  colors,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { alpha, styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import useOffSetTop from "../../hooks/useOffSetTop";
import Logo from "../../components/Logo";
import LanguagePopover from "../../components/LanguagePopover";
import { organizationInfo } from "../../utils/masterData";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 96;

const useStyles = (trigger) =>
  makeStyles((theme) => ({
    iconButton: {
      color: trigger ? colors.blue[900] : colors.grey[50],
      marginLeft: theme.spacing(2),
      padding: 0,
      "&:hover": {
        background: "transparent",
      },
    },
  }));

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  "& .isDesktopActive": {
    color: `${theme.palette.primary.main} !important`,
  },
  "& .isMobileActive": {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  },
}));
const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(["height", "background-color"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("md")]: { height: APP_BAR_DESKTOP },
}));
const ToolbarShadowStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

const WebsiteTopBar = ({ onSidebarOpen }) => {
  const offset = useOffSetTop(100);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
  });
  const classes = useStyles(trigger)();

  return (
    <RootStyle color="transparent">
      <ToolbarStyle
        disableGutters
        sx={{
          ...(offset && {
            bgcolor: "background.default",
            height: { md: APP_BAR_DESKTOP - 20 },
          }),
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
          >
            <Grid item>
              <Logo trigger={trigger} />
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: trigger ? colors.blue[900] : colors.grey[50],
                }}
                variant="h4"
              >
                {organizationInfo.brandName}
              </Typography>
            </Grid>
          </Grid>

          <Hidden smDown>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={5}
            >
              <Grid item>
                <LanguagePopover trigger={trigger} />
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <IconButton
              className={classes.iconButton}
              onClick={onSidebarOpen}
              aria-label="Menu"
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Container>
      </ToolbarStyle>
      {offset && <ToolbarShadowStyle />}
    </RootStyle>
  );
};

export default WebsiteTopBar;
