import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Hidden } from "@mui/material";
import { withTranslation } from "react-i18next";
import WebsiteTopBar from "./WebsiteTopBar";
import WebsiteSideBar from "./WebsiteSideBar";
import WebsiteFooter from "./WebsiteFooter";

const WebsiteLayout = () => {
  const [openSidebar, setOpenSidebar] = useState(false);

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  return (
    <>
      <WebsiteTopBar onSidebarOpen={toggleSidebar} />
      <Hidden smUp>
        <WebsiteSideBar open={openSidebar} onClose={toggleSidebar} />
      </Hidden>
      <Outlet />
      <WebsiteFooter />
    </>
  );
};

export default withTranslation()(WebsiteLayout);
