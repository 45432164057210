import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import localesData from "../utils/localesData";
import { countryToFlag } from "../utils/masterData";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 200,
    paddingLeft: 25,
    paddingTop: 5,
  },
  topTitle: {
    paddingLeft: 25,
    paddingTop: 20,
  },
  title: {
    paddingLeft: 25,
  },
  divider: {
    margin: 20,
  },
}));

const SidebarContent = ({ onContentSelect }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { getLanguages } = localesData();
  const languages = getLanguages;

  const changeLanguage = (languageCode) => {
    i18next.changeLanguage(languageCode);
    onContentSelect();
  };

  return (
    <>
      {/*<Typography variant="overline" className={classes.topTitle}>
        {t("websiteLabelResources")}
      </Typography>
      <Divider variant="middle" className={classes.divider} />*/}
      <Typography variant="overline" className={classes.topTitle}>
        {t("websiteLabelLanguage")}
      </Typography>
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="flex-start"
        className={classes.container}
      >
        {languages.map((language) => (
          <Grid
            item
            key={language.languageCode}
            onClick={() => changeLanguage(language.languageCode)}
          >
            <Typography variant="body2">
              {countryToFlag(`${language.countryCode}`)}&nbsp;&nbsp;
              {language.languageLabel}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default SidebarContent;
