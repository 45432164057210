import React from "react";
import { Card, CardMedia, Grid, Button, Typography } from "@mui/material";
import { colors } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ArrowRightAlt } from "@material-ui/icons";
import Carousel from "react-material-ui-carousel";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100vw",
    height: "100vh",
    borderRadius: 0,
    border: "none",
    boxShadow: "none",
  },
  cardMedia: {
    objectFit: "cover",
    height: "100vh",
  },
  bannerOneText: {
    position: "absolute",
    color: colors.grey[50],
    [theme.breakpoints.up("sm")]: {
      top: "60vh",
      right: "1%",
      marginRight: 20,
    },
    [theme.breakpoints.down("sm")]: {
      top: "80vh",
      marginRight: 20,
    },
  },
  bannerTwoText: {
    position: "absolute",
    color: colors.grey[50],
    [theme.breakpoints.up("sm")]: {
      top: "60vh",
      marginLeft: 20,
    },
    [theme.breakpoints.down("sm")]: {
      top: "80vh",
      marginLeft: 20,
    },
  },
  bannerTextArea: {
    color: colors.grey[50],
    backgroundColor: "transparent",
    "&:hover": {
      color: colors.grey[50],
      backgroundColor: "transparent",
    },
    textTransform: "none",
  },
  bannerOneButton: {
    borderRadius: 3,
    marginTop: 10,
    marginRight: 7,
    backgroundColor: colors.grey[50],
    color: colors.blue[900],
    boxShadow: "1px 1px grey",
    "&:hover": {
      backgroundColor: colors.grey[50],
      color: colors.blue[900],
    },
  },
  bannerTwoButton: {
    borderRadius: 3,
    marginTop: 10,
    marginLeft: 7,
    backgroundColor: colors.grey[200],
    color: colors.blue[900],
    boxShadow: "1px 1px grey",
    "&:hover": {
      backgroundColor: colors.grey[200],
      color: colors.blue[900],
    },
  },
}));

const LandingHero = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Carousel
        autoPlay={false}
        indicators={false}
        navButtonsAlwaysVisible
        navButtonsProps={{
          style: { backgroundColor: "transparent", color: colors.grey[50] },
        }}
      >
        <Card className={classes.card}>
          <div style={{ position: "relative" }}>
            <CardMedia
              component="img"
              image={"/assets/images/shri-mataji-1.jpg"}
              alt="Shri Mataji"
              className={classes.cardMedia}
            ></CardMedia>
            <div className={classes.bannerOneText}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid item align="right" xs={12}>
                  <Button className={classes.bannerTextArea}>
                    <Typography variant="h3" align="right">
                      {t("websiteHomeBannerOneTitlePartOne")}
                      <br />
                      {t("websiteHomeBannerOneTitlePartTwo")}
                    </Typography>
                  </Button>
                </Grid>
                <Grid item align="right" xs={12}>
                  <Button
                    size="medium"
                    variant="contained"
                    endIcon={<ArrowRightAlt />}
                    className={classes.bannerOneButton}
                    href="/#concepts"
                  >
                    <Typography variant="overline">
                      {t("websiteButtonExplore")}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Card>
        <Card className={classes.card}>
          <div style={{ position: "relative" }}>
            <CardMedia
              component="img"
              image={"/assets/images/shri-mataji-2.jpg"}
              alt="Shri Mataji"
              className={classes.cardMedia}
            ></CardMedia>
            <div className={classes.bannerTwoText}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Button className={classes.bannerTextArea}>
                    <Typography variant="h3" align="left">
                      {t("websiteHomeBannerTwoTitlePartOne")}
                      <br />
                      {t("websiteHomeBannerTwoTitlePartTwo")}
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    size="medium"
                    variant="contained"
                    endIcon={<ArrowRightAlt />}
                    className={classes.bannerTwoButton}
                    href="/#solutions"
                  >
                    <Typography variant="overline">
                      {t("websiteButtonExplore")}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Card>
      </Carousel>
    </>
  );
};

export default LandingHero;
